import React, { useState, useEffect, useRef } from "react";
import ProductCard from "../Components/ProductCard";
import { useStateContext } from "../context/ContextProvider";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { useMediaQuery } from "react-responsive";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link, useNavigate } from "react-router-dom";
import {
  IoApps,
  IoSunny,
  IoStarSharp,
  IoPricetag,
  IoDiamondOutline,
  IoStorefront,
  IoWallet,
  IoCart,
  IoClose,
} from "react-icons/io5";
import "../styles/Home.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import required modules
import { Navigation, Pagination, Autoplay } from "swiper/modules";

const Home = () => {
  const [products, setProducts] = useState([]);
  const [newProducts, setNewProducts] = useState([]);
  const [promotions, setPromotions] = useState([]);
  const [sliders, setSliders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [hasMorePages, setHasMorePages] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const chatWindowRef = useRef(null);
  const buttonRef = useRef(null);
  const { likedProducts, setLikedProducts } = useStateContext();
  const { setBasket } = useStateContext();
  const [shortcuts, setShortcuts] = useState([]);
  const isSmallScreen = useMediaQuery({ query: "(max-width: 768px)" });
  const [messages, setMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState("");
  let navigate = useNavigate();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_ADMIN_URL}/api/Shortcut/get-all-shortcuts`)
      .then((response) => response.json())
      .then((data) => setShortcuts(data.data))
      .catch((error) => console.error("Error:", error));
  }, []);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/Product/get-home-promotions?lang=az&PromotionId=2`
    )
      .then((response) => response.json())
      .then((data) => setPromotions(data.data))
      .catch((error) => console.error("Error:", error));
  }, []);

  const addToBasket = (product) => {
    if (product.productCount === 0) {
      alert("Bu məhsul bitib");
    } else {
      const quantity = "1";
      const slug = product.slug;

      const basket = JSON.parse(localStorage.getItem("basket")) || {};

      basket[slug] = quantity;

      localStorage.setItem("basket", JSON.stringify(basket));

      setBasket(basket);
    }
  };

  let initialHomeScroll;
  try {
    initialHomeScroll = sessionStorage.getItem("homeScroll");
  } catch (e) {
    console.error("Cannot access sessionStorage:", e);
  }
  const [homeScroll, setHomeScroll] = useState(initialHomeScroll);
  const [categories, setCategories] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleClick = () => {
    setIsHovered(!isHovered);
  };

  {
    /**Context Liked Products */
  }
  useEffect(() => {
    const storedLikedProducts = localStorage.getItem("likedProducts");
    if (storedLikedProducts) {
      setLikedProducts(JSON.parse(storedLikedProducts));
    }
  }, [setLikedProducts]);
  {
    /**Context Liked Products */
  }

  {
    /**Floating Whatsapp button */
  }
  useEffect(() => {
    const button = buttonRef.current;

    if (window.matchMedia("(hover: none) and (pointer: coarse)").matches) {
      // Touch device
      button.addEventListener("touchstart", () => setIsHovered(true), {
        passive: true,
      });
      button.addEventListener("touchend", () => setIsHovered(false), {
        passive: true,
      });
    } else {
      // Non-touch device
      button.addEventListener("mouseenter", () => setIsHovered(true));
      button.addEventListener("mouseleave", () => setIsHovered(false));
    }

    return () => {
      // Clean up
      button.removeEventListener("touchstart", () => setIsHovered(true));
      button.removeEventListener("touchend", () => setIsHovered(false));
      button.removeEventListener("mouseenter", () => setIsHovered(true));
      button.removeEventListener("mouseleave", () => setIsHovered(false));
    };
  }, []);

  useEffect(() => {
    console.log("isHovered:" + isHovered);
  }, [isHovered]);
  {
    /**Floating Whatsapp button */
  }
  useEffect(() => {
    let isMounted = true;
    const fetchSliders = async () => {
      const resp = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/Slider/get-all-sliders?lang=az`
      );
      const data = await resp.json();
      if (isMounted) {
        setSliders(data.data);
      }
    };

    fetchSliders();
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    console.log("Slider fetch: ", sliders);
  }, [sliders]);

  const f_categories = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/Category/get-all-categories?lang=az`
      );
      const data = await response.json();
      setCategories(data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    f_categories();
  }, []);

  useEffect(() => {
    sessionStorage.setItem("homeScroll", homeScroll);
  }, [homeScroll]);

  useEffect(() => {
    const handleScroll = () => {
      setHomeScroll(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [homeScroll]);

  useEffect(() => {
    homeProductDataFetch();
    homeNewProductFetch();
  }, [page]);

  const homeNewProductFetch = async () => {
    setIsLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/Product/get-all-products?lang=az&pageIndex=${page}&pageSize=12&sortType=orderbydate`
      );
      const data = await response.json();
      if (data.data.items.length > 0) {
        setNewProducts((prevProducts) => {
          const newProducts = [...prevProducts, ...data.data.items];
          const uniqueNewProducts = Array.from(
            new Set(newProducts.map((product) => product.productId))
          ).map((productId) =>
            newProducts.find((product) => product.productId === productId)
          ).slice(0,4);
          sessionStorage.setItem("newProducts", JSON.stringify(uniqueNewProducts));
          return uniqueNewProducts;
        });

        setHasMorePages(true);
      } else {
        setHasMorePages(false);
      }
    } catch (error) {
      setHasMorePages(false);
      console.error("Error fetching data:", error);
    }
  };

  const homeProductDataFetch = async () => {
    setIsLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/Product/get-home-promotions?lang=az&PromotionId=3`
      );

      const data = await response.json();
      if (data.data.length > 0) {
        setProducts((prevProducts) => {
          const newProducts = [...prevProducts, ...data.data];
          const uniqueProducts = Array.from(
            new Set(newProducts.map((product) => product.productId))
          ).map((productId) =>
            newProducts.find((product) => product.productId === productId)
          );
          sessionStorage.setItem("products", JSON.stringify(uniqueProducts));
          return uniqueProducts;
        });

        setHasMorePages(true);
      } else {
        setHasMorePages(false);
      }
    } catch (error) {
      setHasMorePages(false);
      console.error("Error fetching data:", error);
    }
  };

  const [visibleItems, setVisibleItems] = useState(33); // Varsayılan olarak mobilde 3 öğe göster

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;

      if (width >= 1200) {
        setVisibleItems(16); // Masaüstünde 6 öğe göster
      } else {
        setVisibleItems(33); // Diğer durumlarda 3 öğe göster
      }
    };

    // Sayfa yüklendiğinde ve ekran boyutu değiştiğinde çağrılacak
    window.addEventListener("resize", handleResize);
    handleResize(); // Sayfa yüklendiğinde çağrıldığında başlangıçta doğru sayıda öğe gösterilecek

    // Temizleme fonksiyonu
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Close chat window when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (chatWindowRef.current && !chatWindowRef.current.contains(event.target)) {
        setIsChatOpen(false);
      }
    }

    // Add event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Clean up event listener
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [chatWindowRef]);

  const isWithinWorkingHours = () => {
    const now = new Date(); // Current date and time
    const azTime = new Date(now.getTime() + (4 * 60 * 60 * 1000)); // Convert to AZ time (+4 hours)
    const hours = azTime.getUTCHours(); // Get hours in AZ time
  
    // Working hours are from 09:00 to 18:00
    return hours >= 9 && hours < 18;
  };

  const handleMessageChange = (e) => {
    setCurrentMessage(e.target.value);
  };
  
  const handleSendMessage = () => {
    if (currentMessage.trim() !== "") {
      const dataToSend = {
        reportContent: currentMessage
      };
  
      fetch(`${process.env.REACT_APP_BASE_URL}/api/Report/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
      })
      .then(response => {
        if (response.ok) {
          setCurrentMessage("");
          setMessages([...messages, currentMessage]);
        } else {
          console.error('Mesaj gonderilemedi');
        }
      })
      .catch(error => {
        console.error('Mesaj gonderilirken hata meydana geldi:', error);
      });
    }
  };

  /** CHAT SYNC START */

  useEffect(() => {
    // Function to fetch messages
    const fetchMessages = () => {
      fetch(`${process.env.REACT_APP_BASE_URL}/api/Report/get-report`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json(); // Parse the JSON of the response
        })
        .then(data => {
          console.log('Fetched data:', data);
          setMessages(data.data); // Use the 'data' property of the parsed object
        })
        .catch(error => {
          console.error('Error fetching messages:', error);
        });
    };
  
    // Call fetchMessages immediately to load messages on component mount
    fetchMessages();
  
    // Set up the interval to fetch messages periodically
    const intervalId = setInterval(fetchMessages, 5000); // Adjust the interval as needed
  
    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  /* CHAT SYNC END */

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component is unmounted
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  return (
    <>
      <section class="hero-area" style={{ margin: "0 auto" }}>
        <div class="container">
          <div class="row mt-2">
            <div className="col-lg-8 col-12 mb-2">
              <div class="h-100 shadow-sm rounded-2">
                {/**Main Slider */}
                <Swiper
                  pagination={{
                    dynamicBullets: true,
                  }}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  navigation={windowWidth > 768 ? true : false}
                  loop={true}
                  modules={[Autoplay, Pagination, Navigation]}
                  className="campaignSwiper h-100"
                >
                  {Array.isArray(sliders) &&
                    sliders?.map((item, index) => (
                      <SwiperSlide
                        key={index}
                        className="d-flex align-items-center justify-content-center"
                      >
                        <img
                          className="w-100 h-100 rounded custom-swiper-image"
                          src={item?.sliderImage}
                          alt={item.sliderTitle}
                        />
                      </SwiperSlide>
                    ))}
                </Swiper>
                {/**Main Slider */}
              </div>
            </div>
            <div class="col-lg-4 col-12 mb-2 d-none d-lg-block">
              <div class="offer-content shadow-sm h-100 bg-white rounded-2 flashy-border">
                <div class="box-head px-2 py-4 flashy-background">
                  <div class="text-center">
                    <h5 className="flashy-text">Həftənin təklifi</h5>
                  </div>
                </div>
                {/**Second Slider (Promotion Items) */}
                <Swiper
                  pagination={{
                    dynamicBullets: true,
                  }}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  loop={true}
                  modules={[Autoplay, Pagination]}
                  className="offerSwiper pb-5"
                >
                  {promotions.length > 0 &&
                    promotions.map((item, index) => (
                      <SwiperSlide key={index}>
                        <div className="text text-center">
                          <a href={`/az/product/${item.slug}`}>
                            <img
                              src={item.coverImage}
                              className="w-75"
                              alt="#"
                            />
                          </a>
                          <h4>
                            <a
                              href={`/az/product/${item.slug}`}
                              className="text-decoration-none mt-3"
                            >
                              {item.productTitle}
                            </a>
                          </h4>
                          <div class="price">
                            <div className="d-flex align-items-center justify-content-center gap-2">
                              <h6 className="m-0">{item.discountPrice} Azn</h6>
                              <small class="discount-price">
                                {item.price}{" "}
                                Azn
                              </small>
                            </div>
                            <div className="mt-3 mb-3 d-flex align-items-center justify-content-center gap-2">
                              <button
                                className="btn text-white"
                                onClick={() =>
                                  navigate(`/az/cart/${item.slug}`)
                                }
                                style={{ background: "#0B2875" }}
                              >
                                {" "}
                                <strong>Kreditlə al</strong> 
                              </button>
                              <button
                                className="btn btn-success text-white"
                                onClick={() => addToBasket(item)}
                              >
                                {" "}
                                <IoCart />
                              </button>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                </Swiper>
                {/**Second Slider (Promotion Items) */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="trending-product pt-3">
        <div class="container">
          {/**Third Swiper (Categories) */}
          <Swiper
            pagination={{
              dynamicBullets: true,
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            slidesPerView={3}
            breakpoints={{
              640: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 5,
                spaceBetween: 50,
              },
            }}
            loop={true}
            modules={[Autoplay, Pagination]}
            className="trendSwiper"
          >
            {categories.map((category, index) => (
              <SwiperSlide key={index}>
                <div>
                  <a
                    href={`/az/searchResult?categoryId=${category.categoryId}&SortType=orderbydescdate`}
                    className="text-decoration-none text-black text-center w-100 m-1 mb-5"
                  >
                    <div
                      className="home_cat_box d-flex flex-column mx-1"
                      style={{ backgroundColor: "white" }}
                    >
                      <div className="mx-avto h-50 mb-2 d-flex align-items-end justify-content-center">
                        <img
                          src={category.categoryImage}
                          alt={category.categoryTitle}
                          style={{ width: "auto", height: "32px" }}
                        />
                      </div>
                      <div className="mx-avto h-50 d-flex align-items-start justify-content-center">
                        <span className="fs_12px">
                          {category.categoryTitle}
                        </span>
                      </div>
                    </div>
                  </a>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          {/**Third Swiper (Categories) */}
        </div>

        </section>

              {/**New Products */}
      <section class="trending-product section py-5">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="section-title">
                <h2>Yeni məhsullar</h2>
              </div>
            </div>
          </div>
          <div className="col-12 col-xs-12 col-md-12 col-lg-12">
            <div className="row">
              <ProductCard products={newProducts} />
            </div>
          </div>
        </div>
      </section>
      {/**New Products */}

        <section class="trending-product pt-3">
        {!isSmallScreen && (
          <div className="container mt-5">
            <div
              className="shortcuts"
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <div className="row">
                {shortcuts
                  .filter((item) => item.mainPageShortcutId !== 1)
                  .map((item) => (
                    <div className="col-6 p-2">
                      <a
                        href={item.mainPageShortcutUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="w-100"
                      >
                        <img
                          key={item.mainPageShortcutId}
                          src={item.mainPageShortcutPhoto}
                          alt={item.mainPageShortcutTitle}
                          className="rounded-3 shadow-sm"
                          style={{
                            width: "100%",
                            height: "100%",
                            maxHeight: "300px",
                          }}
                        />
                      </a>
                    </div>
                  ))}
                {shortcuts
                  .filter((item) => item.mainPageShortcutId === 1)
                  .map((item) => (
                    <div className="col-12 p-2">
                      <a
                        href={item.mainPageShortcutUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="w-100"
                      >
                        <img
                          key={item.mainPageShortcutId}
                          src={item.mainPageShortcutPhoto}
                          alt={item.mainPageShortcutTitle}
                          className="rounded-3 shadow-sm"
                          style={{
                            width: "100%",
                            height: "100%",
                            maxHeight: "300px",
                          }}
                        />
                      </a>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}
      </section>



      
      {/**Products */}
      <section class="trending-product section py-5">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="section-title">
                <h2>Seçilmiş məhsullar</h2>
              </div>
            </div>
          </div>
          <div className="col-12 col-xs-12 col-md-12 col-lg-12">
            <div className="row">
              <ProductCard products={products} />
            </div>
          </div>
        </div>
      </section>
      {/**Products */}
      {/**Web Contact */}
      <div className="whatsapp-button" ref={buttonRef}>
        <a
          href="#"
          onClick={(e) => { e.preventDefault();
            setIsChatOpen(!isChatOpen);
          }
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://static.dosttech.az/2/card_types/contactussquare.png"
            alt="WhatsApp"
          />
        </a>
        {isHovered && (
          <div className="extra-icons">
            <a
              href="https://api.whatsapp.com/send?phone=+994772551221&text=Salam,%20siz%C9%99%20dosttech.az%20sayt%C4%B1ndan%20yaz%C4%B1ram."
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://static.dosttech.az/1/wp2.png"
                alt="WhatsApp"
              />
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=+994774062221&text=Salam,%20dosttech.az%20m%C9%99hsullar%C4%B1%20il%C9%99%20ba%C4%9Fl%C4%B1%20V%C4%B0DEOG%C3%96R%C3%9CNT%C3%9CL%C3%9C%20z%C9%99ng%20etm%C9%99nizi%20xahi%C5%9F%20edir%C9%99m."
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://static.dosttech.az/1/wp3.png"
                alt="WhatsApp"
              />
            </a>
          </div>
        )}
      </div>
      {isChatOpen && (
        <div className="chat-window" ref={chatWindowRef}>
          <div className="chat-header">
            <h4>Onlayn dəstək <span className={`status-circle ${isWithinWorkingHours() ? 'green' : 'red'}`}></span></h4>
            <button onClick={() => setIsChatOpen(false)}><IoClose /></button>
          </div>
          <div className="chat-body">
            {messages.slice().reverse().map((message) => (
            <div key={message.generalUserReportId} className="message-bubble">
              <p className="message-content">{message.reportContent}</p>
              <p className="message-date">{new Date(message.createDate).toLocaleString()}</p>
            </div>
            ))}
            <p className="operator-message">Operatörlerimiz en kısa sürede sizinle alaka saklayacaklar!</p>
          </div>
          <div className="chat-footer">
            <input type="text" placeholder="Mesajınızı daxil edin..." value={currentMessage} onChange={handleMessageChange} />
            <button onClick={handleSendMessage}>Gonder</button>
          </div>
        </div>
      )}
      {/**Web Contact */}
    </>
  );
};

export default Home;
