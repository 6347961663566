import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import ProductCard from "../Components/ProductCard";
import { Link, useNavigate } from "react-router-dom";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import {
  IoCartOutline,
  IoHeartOutline,
  IoCallOutline,
  IoSearch,
  IoLogoFacebook,
  IoLogoInstagram,
  IoLogoYoutube,
  IoLogoWhatsapp,
  IoLogoTiktok,
  IoLogoLinkedin,
  IoLogoTwitter,
  IoNavigateCircle,
  IoApps,
  IoClose,
  IoCaretDownOutline,
  IoCaretForward,
  IoMenu,
  IoShareSocialSharp,
  IoInformationCircleOutline,
  IoWalletOutline,
  IoSwapHorizontal,
  IoPersonOutline,
} from "react-icons/io5";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import required modules
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import FsLightbox from "fslightbox-react";
import "../styles/ProductDetail.css";
import { useStateContext } from "../context/ContextProvider";
import ReactModal from "react-modal";

const ProductDetail = () => {
  const [product, setProduct] = useState({});
  const [productParameters, setProductParameters] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [selectedImg, setSelectedImg] = useState("");
  const [videoId, setVideoId] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [toggler, setToggler] = useState(false);
  const navigate = useNavigate();

  const quantityRef = useRef();
  const {
    lang,
    token,
    basket,
    setBasket,
    addProductToComparison,
    productComparisons,
    likedProducts,
    setLikedProducts,
  } = useStateContext();

  const url = window.location.pathname;
  const segments = url.split("/");
  console.log(segments);
  let slug = segments[3];

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const youtubeRegex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

  const checkForYouTubeLink = (description) => {
    const match = description?.match(youtubeRegex);
    if (match && match[1]) {
      setVideoId(match[1]);
    }
  };

  React.useEffect(() => {
    checkForYouTubeLink(product.productDescription);
  }, [product]);

  useEffect(() => {
    getProduct();
    window.scrollTo(0, 0);
  }, []);

  const sources = product?.productGalleries?.map((gallery) => ({
    src: gallery.productGalleryFile,
  }));

  const getProduct = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/Product/get-product?LanguageCode=${lang}&Slug=${slug}`
      );
      const data = await response.json();
      if (data && data.data) {
        console.log("Product data:", data.data);
        setProduct(data.data);
        setProductParameters(data.data.parameters);
        setSelectedImg(data.data.coverImage);
      }
    } catch (error) {
      console.error("Error get product data:", error);
    }
  };
  useEffect(() => {
    console.log("Selected image:", selectedImg);
  }, [selectedImg]);

  console.log(videoId);

  const handleImageChange = (e) => {
    setSelectedImg(e.target.src);
  };

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const addToBasket = () => {
    if (product.productCount === 0) {
      alert("Bu məhsul bitib");
    } else {
      const quantity = quantityRef.current.value;
      const slug = product.slug;

      const basket = JSON.parse(localStorage.getItem("basket")) || {};

      basket[slug] = quantity;

      localStorage.setItem("basket", JSON.stringify(basket));

      setBasket(basket);

      if (token) {
        // /Basket post işlemi olacak
      }
    }
  };

  const handleCompareClick = (productId) => {
    addProductToComparison(productId);
  };

  useEffect(() => {
    const storedLikedProducts = localStorage.getItem("likedProducts");
    if (storedLikedProducts) {
      setLikedProducts(JSON.parse(storedLikedProducts));
    }
  }, [setLikedProducts]);

  const handleLike = (productSlug) => {
    console.log("At product like", productSlug, likedProducts);

    setLikedProducts((prevLikedProducts) => {
      let updatedLikedProducts;

      if (prevLikedProducts.includes(productSlug)) {
        updatedLikedProducts = prevLikedProducts.filter(
          (slug) => slug !== productSlug
        );
      } else {
        updatedLikedProducts = [...prevLikedProducts, productSlug];
      }

      console.log("After product like", updatedLikedProducts);
      localStorage.setItem(
        "likedProducts",
        JSON.stringify(updatedLikedProducts)
      );

      return updatedLikedProducts;
    });
  };

  return (
    <div className="container_m">
      <section className="item-details section">
        <div className="container">
          <div className="top-area">
            <div className="row align-items-start">
              <div className="col-lg-5 col-md-12 col-12">
                <div className="product-images">
                  <main id="gallery">
                    <div className="main-img">
                      <img
                        onClick={() => setToggler(!toggler)}
                        src={selectedImg}
                        id="current"
                        alt="product"
                      />
                    </div>
                    <Swiper
                      pagination={{
                        dynamicBullets: true,
                      }}
                      navigation={true}
                      modules={[Pagination, Navigation]}
                      slidesPerView={2}
                      breakpoints={{
                        640: {
                          slidesPerView: 2,
                          spaceBetween: 12,
                        },
                        768: {
                          slidesPerView: 3,
                          spaceBetween: 16,
                        },
                        1024: {
                          slidesPerView: 5,
                          spaceBetween: 20,
                        },
                      }}
                      className="images campaignSwiper py-4"
                    >
                      {product.productGalleries &&
                      product.productGalleries.length > 0 ? (
                        product.productGalleries.map((gallery, index) => (
                          <SwiperSlide key={index}>
                            <img
                              src={gallery.productGalleryFile}
                              alt=""
                              className="img-fluid"
                              onClick={(e) => handleImageChange(e)}
                            />
                          </SwiperSlide>
                        ))
                      ) : (
                        <p>No gallery images available</p>
                      )}
                    </Swiper>
                    <Lightbox
                      open={toggler}
                      close={() => setToggler(false)}
                      slides={sources}
                    />
                  </main>
                </div>
              </div>
              <div className="col-lg-7 col-md-12 col-12">
                <div className="product-info">
                  <h2 className="title">{product.productTitle}</h2>
                  <p className="category">
                    Kateqoriya:
                    <a href="javascript:void(0)">{product.categoryTitle}</a>
                  </p>
                  <div className="price-container">
                    <span className="credit-price">{product.creditPrice} AZN</span>
                    <h3 className="price" style={{ color: "#0B2875" }}>
                    {product.price} AZN
                    </h3>
                  </div>
                  <div className="info-text">
                    <p>{product.productDescription}</p>
                    {videoId && (
                      <div className="wish-button">
                        <button onClick={openModal} className="btn">
                          Ürün Videosunu İzle
                        </button>
                      </div>
                    )}
                    <ReactModal
                      isOpen={modalIsOpen}
                      onRequestClose={closeModal}
                      contentLabel="YouTube Video"
                      className="videoModal"
                    >
                      <button
                        type="button"
                        className="btn fs-2 p-0"
                        onClick={closeModal}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                      <iframe
                        className="w-100 h-100"
                        src={`https://www.youtube.com/embed/${videoId}`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="YouTube Video"
                      ></iframe>
                    </ReactModal>
                  </div>
                  {/* <div
                    className="info-text"
                    dangerouslySetInnerHTML={{
                      __html: processDescription(product.productDescription + " https://www.youtube.com/watch?v=oqoLA-H9y0s"),
                    }}
                  /> */}
                  {/* <p className="info-text">{product.productDescription}</p> */}
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-12">
                      <div className="form-group color-option">
                        <label className="title-label" htmlFor="size">
                          Rəng seç
                        </label>
                        <div className="single-checkbox checkbox-style-1">
                          <input type="checkbox" id="checkbox-1" checked />
                          <label htmlFor="checkbox-1">
                            <span></span>
                          </label>
                        </div>
                        <div className="single-checkbox checkbox-style-2">
                          <input type="checkbox" id="checkbox-2" />
                          <label htmlFor="checkbox-2">
                            <span></span>
                          </label>
                        </div>
                        <div className="single-checkbox checkbox-style-3">
                          <input type="checkbox" id="checkbox-3" />
                          <label htmlFor="checkbox-3">
                            <span></span>
                          </label>
                        </div>
                        <div className="single-checkbox checkbox-style-4">
                          <input type="checkbox" id="checkbox-4" />
                          <label htmlFor="checkbox-4">
                            <span></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-12">
                      <div className="form-group quantity">
                        <label htmlFor="color">Ədəd</label>
                        {product.productCount > 0 ? (
                          <input
                            ref={quantityRef}
                            type="number"
                            className="form-control"
                            value={1}
                            max={product.productCount}
                          />
                        ) : (
                          <p style={{ color: "red" }}>Məhsul bitib</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="bottom-content">
                    <div className="row align-items-end">
                      <div className="col-lg-3 col-md-3 col-12">
                        <div className="wish-button">
                          <button className="btn" onClick={addToBasket}>
                            <IoCartOutline /> Səbətə əlavə et
                          </button>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-3 col-12">
                        <div className="wish-button">
                          <button
                            className="btn"
                            onClick={() => navigate(`/az/cart/${product.slug}`)}
                          >
                            {" "}
                            <strong>Kreditlə al</strong>
                          </button>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-3 col-12">
                        <div className="wish-button">
                          <button
                            className="btn"
                            onClick={() => handleCompareClick(product.slug)}
                            style={{
                              background:
                                productComparisons.includes(product.slug) &&
                                "#0B2875",
                              color:
                                productComparisons.includes(product.slug) &&
                                "#fff",
                            }}
                          >
                            <IoSwapHorizontal /> Müqayyisə et
                          </button>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-3 col-12">
                        <div className="wish-button">
                          <button
                            className="btn"
                            onClick={() => handleLike(product.slug)}
                            style={{
                              background:
                                likedProducts.includes(product.slug) && "red",
                              color:
                                likedProducts.includes(product.slug) && "#fff",
                            }}
                          >
                            <IoHeartOutline /> Bəyən
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="product-details-info">
            <div className="single-block">
              <h3 className="mb-4">Ürün Detayları</h3>
              <div className="row">
                <div className="col-lg-6 col-12">
                  <div className="info-body custom-responsive-margin">
                    {productParameters && productParameters.length > 0 && (
                      <div className="single-parametrs-details">
                        {productParameters
                          .slice(0, showMore ? productParameters.length : 5)
                          .map((parameter, index) => (
                            <div
                              className="detail-key-value text_cap"
                              key={index}
                            >
                              <div className="row alert alert-light">
                                <div className="col-6">
                                  <span className="detail-key">
                                    {parameter.parameterTitle}:
                                  </span>
                                </div>
                                <div className="col-6 d-flex align-items-center justify-content-end">
                                  <span className="detail-value fw-bold">
                                    {parameter.parameterValue}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))}
                        {productParameters.length > 5 && (
                          <div className="w-100 d-flex align-items-center justify-content-center">
                            <button
                              onClick={toggleShowMore}
                              className="btn btn-outline-primary mx-auto mt-3"
                            >
                              {showMore ? "Daha az göstər" : "Ətraflı göstər"}
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="info-body">
                    {/* <h4>Specifications</h4>
                                <ul className="normal-list">
                                    <li><span>Weight:</span> 35.5oz (1006g)</li>
                                    <li><span>Maximum Speed:</span> 35 mph (15 m/s)</li>
                                    <li><span>Maximum Distance:</span> Up to 9,840ft (3,000m)</li>
                                    <li><span>Operating Frequency:</span> 2.4GHz</li>
                                    <li><span>Manufacturer:</span> GoPro, USA</li>
                                </ul>
                                <h4>Shipping Options:</h4>
                                <ul className="normal-list">
                                    <li><span>Courier:</span> 2 - 4 days, $22.50</li>
                                    <li><span>Local Shipping:</span> up to one week, $10.00</li>
                                    <li><span>UPS Ground Shipping:</span> 4 - 6 days, $18.00</li>
                                    <li><span>Unishop Global Export:</span> 3 - 4 days, $25.00</li>
                                </ul>
                  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProductDetail;
