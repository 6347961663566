import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useStateContext } from "../context/ContextProvider";

const CompleteOrder = () => {
  let params = useParams();
  const [product, setProduct] = useState({});
  const productId = params.id;
  const { lang } = useStateContext();
  const [formSent, setFormSent] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "dosttech",
    lastName: "dosttech",
    phoneNumber: "",
    address: "dosttech",
    note: "dosttech",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedFormData = {
      ...formData,
      productId: product.productId,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/Order/add-fast-order`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedFormData),
        }
      );

      if (response.ok) {
        console.log("Form gönderildi!");
        setFormSent(true);
      } else {
        console.error("Form gönderilirken bir hata oluştu.");
        setFormSent(false);
      }
    } catch (error) {
      console.error("Bir hata oluştu:", error);
      setFormSent(false);
    }
  };

  const getProduct = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/Product/get-product?LanguageCode=${lang}&Slug=${productId}`
      );
      const data = await response.json();
      if (data && data.data) {
        console.log("Product data:", data.data);
        setProduct(data.data);
      }
    } catch (error) {
      console.error("Error get product data:", error);
    }
  };

  useEffect(() => {
    getProduct();
  }, []);

  return (
    <div className="container py-5" style={{ minHeight: "100vh" }}>
      <div className="d-flex flex-md-row flex-column gap-4 p-4 border rounded">
        <form
          className="d-flex align-items-start flex-column justify-content-center flex-grow-1 py-2"
          onSubmit={handleSubmit}
        >
          <div className="w-100">
            <label for="inputPhone" className="form-label">
            Telefon nömrəsi
            </label>
            <div className="row w-full">
              <div className="col-md-6">
                <input
                  type="tel"
                  className="form-control mb-3"
                  id="inputPhone"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-6">
                <button type="submit" className="btn btn-primary">
                  Gönder
                </button>
              </div>
            </div>
          </div>
          <small>Operator sizinlə qısa müddətdə əlaqə saxlıyacaq</small>
        {formSent && (
          <div class="alert alert-success mt-3 w-50" role="alert">
            Form Gönderildi!
          </div>
        )}
        </form>
        <div className=" flex-grow-2">
          <div className="card-body">
            <div className="text text-center">
              <a href={`/az/product/${product.slug}`}>
                <img
                  src={product.coverImage}
                  className="img-fluid"
                  style={{ width: "320px" }}
                  alt="#"
                />
              </a>
              <h4>
                <a
                  href={`/az/product/${product.slug}`}
                  className="text-decoration-none mt-3"
                >
                  {product.productTitle}
                </a>
              </h4>
              <div class="price">
                <div className="d-flex align-items-center justify-content-center gap-2">
                  <h6 className="m-0">{product.price} Azn</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompleteOrder;
